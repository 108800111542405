
import React from "react";
import { Scrollbars } from "react-custom-scrollbars";

const CustomScrollbar = (props) => {
  const thumbStyle = {
    backgroundColor: "#426a8e",
    borderRadius: "5px",
  };

  return (
    <Scrollbars
      autoHide={false}
      {...props}
      renderThumbVertical={({ style, ...props }) => (
        <div {...props} style={{ ...style, ...thumbStyle }} />
      )}
      renderTrackHorizontal={({ style, ...props }) => (
        <div {...props} />
      )}
      thumbSize={300}
      thumbMinSize={100}
      // className={css`
      //   div:first-child {
      //     background-color: #f1f1f1;
      //   }
      // `}
      // className={css`
      //   div:first-of-type{
      //     background-color: #f1f1f1;
      //   }
      // `}
      
    />
  );
};

export default CustomScrollbar;
