import React, {useState} from 'react';
import {Form, Input, message, Radio, Space, Row, Col} from 'antd';
import {
    EditOutlined, GlobalOutlined,
    MailOutlined,
    PhoneOutlined,
    UserOutlined
} from '@ant-design/icons';
import SelectRequest from "@/components/Input/SelectRequest";
import InputGroup from "@/components/Input/InputGroup";
import SubmitBtn from "@/components/Forms/SubmitBtn";
import AxiosInstance from "@/util/Api";
import {useMutation} from "react-query";
import {useHistory} from "react-router-dom";
import "./registration.css";

const Registration = () => {
    const [form] = Form.useForm();
    const [continent, setContinent] = useState("");
    const history = useHistory();

    const onContinetChange = (e) => {
        console.log('radio checked', e.target.value);
        setContinent(e.target.value);
    };

    const handleFinish = (values) => {
        console.log('Form Values: ', values);
        mutate(values);
    };

    const addMemberInfo = async (values) => {
        const { data: response } = await AxiosInstance.post("sys/registration", values);
        return response;
    };

    const { mutate, isLoading } = useMutation(addMemberInfo, {
        onSuccess: (data) => {
            if (data.status === 1) {
                message.success(data.msg);
                history.push("/registrationsuccess")
            } else if (data.status === 0) {
                message.warning(data.msg);
            } else if (data.status === 3) {
                form.setFields(data.data);
            } else {
                message.error(data.msg);
            }
        },
        onError: () => {
            message.error("Server Error...");
        },
    });

    const handleFinishFailed = (errorInfo) => {
        console.log('Form Submission Failed: ', errorInfo);
        message.error('Please enter required information');
    };

    return (
        <>
            <div style={{overflow: "auto", height: '100%', background: "url('/assets/images/login_page_bg.jpg')", backgroundSize: "cover"}}>
            <div className={"registrationMainBody"}>
                <div className={"registrationBody"}>
                    <div style={{width: '100%', textAlign: 'center', padding: '30px'}}>
                        <img src={"/assets/images/eChurchNewLogo.png"} style={{width: '200px'}} />
                    </div>
                    <div>
                        <h1 style={{textAlign: 'center'}}>The Church of Pentecost E-Church Global Member Onboarding Form.</h1>
                        <p>We welcome you with open arms!</p>
                        <p>
                            Are you a Christian and find yourself in a city or nation without a nearby church? Are you a member of The Church of Pentecost but unable to attend due to the long drive to the nearest place of worship? Perhaps you are a new believer in Christ Jesus but face challenges openly practicing your faith or fellowshipping with others. This invitation is also for sailors, travelers, the sick and immobile, the aged at home, the incarcerated, and anyone facing difficulties joining a physical church.
                        </p>
                        <p style={{marginTop: '30px'}}>
                            By joining the E-Church, you will connect with like-minded believers from around the world, participate in dynamic worship sessions, engage in interactive Bible studies, and receive pastoral care, all from the comfort of your own space.
                        </p>
                        <p>
                            Don’t let distance, illness, or any other challenge keep you from experiencing the fellowship and growth that comes from being part of a vibrant church community. Fill out the form and take the first step toward joining The Church of Pentecost E-Church today.
                        </p>
                        <p>
                            For further details or assistance, please contact us at <strong>echurch@thecophq.org</strong> or WhatsApp/Call <a href="tel:+233243172902">+233243172902</a>/<a href={"tel:+233244156769"}>+233244156769</a> .
                        </p>
                        <p>
                            You can also contact your Bloc Representative for further details:
                            <div style={{overflow: "auto"}}>
                                <table width={'100%'} className={"contactTable"}>
                                    <thead>
                                        <tr>
                                            <th style={{textAlign: 'left'}}>Continent/Bloc</th>
                                            <th style={{textAlign: 'left'}}>Representative Name</th>
                                            <th style={{textAlign: 'left'}} >Contact</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>East Africa</td>
                                            <td>Ps. David Akili</td>
                                            <td><PhoneOutlined /> <a href={"tel:+257 79 95 67 29"}>+257 79 95 67 29</a> </td>
                                        </tr>
                                        <tr>
                                            <td>Francophone Africa</td>
                                            <td>Ps. Dennis Owusu Brown</td>
                                            <td><PhoneOutlined /> <a href={"tel:+222 48 12 64 64"}>+222 48 12 64 64</a> </td>
                                        </tr>
                                        <tr>
                                            <td>Middle East</td>
                                            <td>Ps. Peter Abdulla Darlington</td>
                                            <td><PhoneOutlined /> <a href={"tel:+973 3302 5512"}>+973 3302 5512</a></td>
                                        </tr>
                                        <tr>
                                            <td>Asia & Oceanic</td>
                                            <td>Ovr. Sunil Jaiswal</td>
                                            <td><PhoneOutlined /> <a href={"tel:+91 83759 33090"}>+91 83759 33090</a></td>
                                        </tr>
                                        <tr>
                                            <td>Europe</td>
                                            <td>Ps. Frank Frimpong</td>
                                            <td><PhoneOutlined /> <a href={"tel:+39 320 769 9975"}>+39 320 769 9975</a></td>
                                        </tr>
                                        <tr>
                                            <td>South America</td>
                                            <td>Ps. Lawrence Kyei-Asante</td>
                                            <td><PhoneOutlined /> <a href={"tel:+1(313) 556-6898"}>+1(313) 556-6898</a></td>
                                        </tr>
                                        <tr>
                                            <td>North America</td>
                                            <td>Ps. Samuel Morgan</td>
                                            <td><PhoneOutlined /> <a href={"tel:+1(470) 776-8175"}>+1(470) 776-8175</a></td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </p>
                        <p>
                            <strong>By: The Global Executive Committee.</strong>
                        </p>
                    </div>
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={handleFinish}
                        onFinishFailed={handleFinishFailed}
                    >
                        {/* Name Field */}
                        <Form.Item
                            label="Full Name"
                            name="name"
                            rules={[{ required: true, message: 'Please enter your full name' }]}
                        >
                            <Input prefix={<UserOutlined />} placeholder="Enter your name" />
                        </Form.Item>

                        <Form.Item
                            label="Gender"
                            name="gender"
                            rules={[{ required: true, message: 'Please select an option' }]}
                        >
                            <Radio.Group>
                                <Space direction={"horizontal"}>
                                    <Radio value={"Male"} >Male</Radio>
                                    <Radio value={"Female"} >Female</Radio>
                                </Space>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item
                            label="Choose your age range (Please choose the range within which your age falls.)"
                            name="age_range"
                            rules={[{ required: true, message: 'Please select an option' }]}
                        >
                            <Radio.Group>
                                <Space direction={"vertical"}>
                                    <Radio value={"18-25years"} >18-25years</Radio>
                                    <Radio value={"26-35years"} >26-35years</Radio>
                                    <Radio value={"36-50years"} >36-50years</Radio>
                                    <Radio value={"51years - 64years"} >51years - 64years</Radio>
                                    <Radio value={"65+"} >65+</Radio>
                                </Space>
                            </Radio.Group>
                        </Form.Item>

                        {/* Phone Number Field */}
                        <Form.Item
                            label="Mobile/Telephone"
                            name="phone"
                            rules={[{ required: true, message: 'Please enter your phone number' }]}
                        >
                            <Input prefix={<PhoneOutlined />} placeholder="Enter your phone number" />
                        </Form.Item>

                        {/* Email Field */}
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[
                                { required: true, message: 'Please enter your email' },
                                { type: 'email', message: 'Please enter a valid email' },
                            ]}
                        >
                            <Input prefix={<MailOutlined />} placeholder="Enter your email" />
                        </Form.Item>

                        {/* Experience Field */}
                        <Form.Item
                            label="City, Province/Region"
                            name="city"
                            rules={[{ required: true, message: 'Please ender City, Province/Region' }]}
                        >
                            <Input prefix={<EditOutlined />} placeholder="Please ender City, Province/Region" />
                        </Form.Item>

                        <SelectRequest
                            label={"Country"}
                            name={"country"}
                            required={true}
                            path={"sys/countries"} />

                        <Form.Item
                            label="Choose which continent/bloc applies to you."
                            name="continent"
                            rules={[{ required: true, message: 'Please select an option' }]}
                        >
                            <Radio.Group onChange={onContinetChange}>
                                <Space direction={"vertical"}>
                                    <Radio value={"Francophone Africa"} >Francophone Africa</Radio>
                                    <Radio value={"Anglophone Africa"} >Anglophone Africa</Radio>
                                    <Radio value={"East Africa"} >East Africa</Radio>
                                    <Radio value={"North America"} >North America</Radio>
                                    <Radio value={"South America"} >South America</Radio>
                                    <Radio value={"Europe"} >Europe</Radio>
                                    <Radio value={"Asia and Oceanic"} >Asia and Oceanic</Radio>
                                    <Radio value={"Middle East"} >Middle East</Radio>
                                    <Radio value={"Other"} >Other
                                        {continent === "Other" ? <Input style={{ width: 200, marginLeft: 10 }} /> : null}
                                    </Radio>
                                </Space>
                            </Radio.Group>
                        </Form.Item>

                        <Row gutter={24}>
                            <Col lg={12} md={12} xl={12} xs={24}>
                                <InputGroup prefix={<EditOutlined />} placeholder={"Enter your Religion/Faith"} name="religion" required={false} label={"Religion/Faith"}  />
                            </Col>
                            <Col lg={12} md={12} xl={12} xs={24}>
                                <InputGroup prefix={<EditOutlined />} name="denomination" placeholder={"Enter denomination"} required={false} label={"Denomination"}  />
                            </Col>
                            <Col lg={12} md={12} xl={12} xs={24}>
                                <InputGroup prefix={<GlobalOutlined />} name="language" placeholder={"Enter languages you can speak"} required={false} label={"Language(s) spoken or preferred."}  />
                            </Col>
                        </Row>
                        <Form.Item
                            label="I am joining the E-Church"
                            name="join_as"
                            rules={[{ required: true, message: 'Please select an option' }]}
                        >
                            <Radio.Group onChange={onContinetChange}>
                                <Space direction={"vertical"}>
                                    <Radio value={"As an Individual"} >As an Individual</Radio>
                                    <Radio value={"With my Spouse"} >With my Spouse</Radio>
                                    <Radio value={"With my Spouse and Children"} >With my Spouse and Children</Radio>
                                    <Radio value={"With  my Children"} >With  my Children</Radio>
                                </Space>
                            </Radio.Group>
                        </Form.Item>

                        <InputGroup prefix={<EditOutlined />} name="spouse_name" placeholder={"Enter your spouse name"} required={false} label={"If you are joining with your spouse, please indicate the full name."}  />

                        <Form.Item
                            label="If you are joining with child(ren), indicate their age ranges."
                            name="child_age_range"
                            rules={[{ required: true, message: 'Please select an option' }]}
                        >
                            <Radio.Group onChange={onContinetChange}>
                                <Space direction={"vertical"}>
                                    <Radio value={"0-6"} >0-6</Radio>
                                    <Radio value={"7-12"} >7-12</Radio>
                                    <Radio value={"13+"} >13+</Radio>
                                </Space>
                            </Radio.Group>
                        </Form.Item>

                        <SubmitBtn isLoading={isLoading} cancelAllow={false} />
                    </Form>
                </div>
            </div>
            </div>
        </>
    );
};

export default Registration;
