import { css } from "@emotion/css";
import { Form, TimePicker } from "antd";
import React from "react";

const InputTime = ({
  name = "",
  label = "",
  required = false,
  placeholder = "",
  index,
  ...arg
}) => {
  return (
    <>
      <Form.Item
        rules={[{ required: required }]}
        label={label}
        name={name}
        className={css`

        .ant-form-item-label{
          padding-bottom: 0!important;
        }
      `}
      >
        <TimePicker {...arg} use12Hours format="h:mm a" className={css`
          width: 100%;
        `} />
      </Form.Item>
    </>
  );
};

export default InputTime;
