import { Col } from "antd";
import InputCheckbox from "@/components/Input/InputCheckbox";
import InputDate from "@/components/Input/InputDate";
import InputEmail from "@/components/Input/InputEmail";
import InputGroup from "@/components/Input/InputGroup";
import NumberInput from "@/components/Input/NumberInput";
import InputTextArea from "@/components/Input/InputTextArea";
import SelectRequest from "@/components/Input/SelectRequest";
import SingleUpload from "@/components/Input/SingleUpload";
import FileUpload from "@/components/Input/FileUpload";
import InputSelect from "@/components/Input/InputSelect";
import InputTime from "@/components/Input/InputTime";
import {
  SelectMaritalStatus,
  SelectMarriageType,
  SelectMemberStatus,
  SelectStatus,
} from "./CISUI";
import InputSwitch from "@/components/Input/inputSwitch";
import CustomCKEditor from "@/components/Input/CustomCKEditor";
import ImageUpload from "@/components/Input/ImageUpload";
import InputRangePicker from "@/components/Input/RangePicker";
import InputPassword from "@/components/Input/InputPassword";

export const CheckInputType = (input, index, type) => {
  switch (input.type) {
    case "text":
      return (
        <InputGroup
          name={input.name}
          label={input.label}
          placeholder={input.placeholder}
          required={type === "adv" ? false : input.required}
          disabled={input.disabled}
        />
      );
    case "password":
      return (
          <InputPassword
              name={input.name}
              label={input.label}
              placeholder={input.placeholder}
              required={type === "adv" ? false : input.required}
              disabled={input.disabled}
          />
      );
    case "number":
      return (
        <NumberInput
          name={input.name}
          label={input.label}
          placeholder={input.placeholder}
          required={type === "adv" ? false : input.required}
          disabled={input.disabled}
          initialValue={input.initialValue}
        />
      );
    case "select":
      return (
        <SelectRequest
          path={input.path}
          name={input.name}
          mode={input?.isMultiple ? "multiple" : null}
          initialValue={input.initialValue}
          label={input.label}
          placeholder={input.placeholder}
          disabled={input.disabled}
          required={type === "adv" ? false : input.required}
        />
      );
    case "date":
      return (
        <InputDate
          name={input.name}
          label={input.label}
          placeholder={input.placeholder}
          required={type === "adv" ? false : input.required}
          disabled={input.disabled}
          {...input}
        />
      );
      case "rangepicker":
        return (
          <InputRangePicker
            name={input.name}
            label={input.label}
            placeholder={input.placeholder}
            required={type === "adv" ? false : input.required}
            disabled={input.disabled}
            {...input}
          />
        );
      case "daterange":
        return (
            <InputRangePicker
                name={input.name}
                label={input.label}
                showTime={false}
                placeholder={input.placeholder}
                required={type === "adv" ? false : input.required}
                disabled={input.disabled}
                {...input}
            />
        );
    case "time":
      return (
        <InputTime
          name={input.name}
          label={input.label}
          placeholder={input.placeholder}
          required={type === "adv" ? false : input.required}
          disabled={input.disabled}
        />
      );
    case "textarea":
      return (
        <InputTextArea
          name={input.name}
          label={input.label}
          placeholder={input.placeholder}
          rows={input.rows}
          required={type === "adv" ? false : input.required}
          disabled={input.disabled}
        />
      );
    case "status":
      return (
        <SelectStatus
          required={type === "adv" ? false : input.required}
          disabled={input.disabled}
        />
      );
    case "member_status":
      return (
        <SelectMemberStatus
          required={type === "adv" ? false : input.required}
          disabled={input.disabled}
        />
      );
    case "marriage_type":
      return (
        <SelectMarriageType
          required={type === "adv" ? false : input.required}
          disabled={input.disabled}
        />
      );
    case "marital_status":
      return (
        <SelectMaritalStatus
          required={type === "adv" ? false : input.required}
          disabled={input.disabled}
        />
      );
    case "checkbox":
      return (
        <InputCheckbox
          required={type === "adv" ? false : input.required}
          name={input.name}
          label={input.label}
          label_value={input.label_value}
          disabled={input.disabled}
          checked={input.checked}
        />
      );
    case "email":
      return (
        <InputEmail
          name={input.name}
          label={input.label}
          placeholder={input.placeholder}
          required={type === "adv" ? false : input.required}
          disabled={input.disabled}
        />
      );
    case "file":
      return (
        <FileUpload
          label={input.label}
          name={input.name}
          source={input.source}
          size={input.size}
          required={type === "adv" ? false : input.required}
          disabled={input.disabled}
          extra={input.extra}
          maxCount={input.maxcount}
        />
      );
    case "singlefile":
      return (
        <SingleUpload
          label={input.label}
          placeholder={input.placeholder}
          name={input.name}
          source={input.source}
          required={type === "adv" ? false : input.required}
          disabled={input.disabled}
          extra={input.extra}
        />
      );
    case "image":
      return (
        <ImageUpload
          label={input.label}
          name={input.name}
          maxCount={input.maxCount}
        />
      );
    case "staticselect":
      return (
        <InputSelect
          label={input.label}
          name={input.name}
          required={type === "adv" ? false : input.required}
          extra={input.extra}
          items={input.items}
          disabled={input.disabled}
          placeholder={input.placeholder}
          initialValue={input.value}
          mode={input?.isMultiple ? "multiple" : null}
        />
      );
    case "switch":
      return (
        <InputSwitch
          required={type === "adv" ? false : input.required}
          name={input.name}
          label={input.label}
          label_value={input.label_value}
          disabled={input.disabled}
          checked={input.checked}
          defaultValue={input.value}
        />
      );
    case "texteditor":
      return (
        <CustomCKEditor
          name={input.name}
          label={input.label}
          placeholder={input.placeholder}
          rows={input.rows}
          required={type === "adv" ? false : input.required}
          disabled={input.disabled}
        />
      );
    default:
      return (
        <InputGroup
          name={input.name}
          label={input.label}
          placeholder={input.placeholder}
          required={type === "adv" ? false : input.required}
          disabled={input.disabled}
        />
      );
  }
};

export const InputField = ({ input, fieldIndex, type }) => {
  return (
    <>
      {input?.map((item, index) => {
        // console.log("isShow", item);
        if (item.isShow) {
          return (
            <Col span={24} lg={type === "adv" ? 6 : item.size} key={index}>
              {fieldIndex === undefined
                ? CheckInputType(item, (fieldIndex = undefined), type)
                : CheckInputType(item, fieldIndex)}
            </Col>
          );
        }
      })}
    </>
  );
};
