import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {Elements, PaymentElement} from '@stripe/react-stripe-js';
import StripeCheckout from './paymentform';
import axios from "@/util/Api";
import config from "@/util/config";
import {Button, Card, Col, Form, Input, Row, Select} from "antd";
import {useLocation} from "react-router-dom";
const { Option } = Select;

const stripePromise = loadStripe(config.stripAPIKey);

const App = () => {
    const [clientSecret, setClientSecret] = useState('');
    const [amount, setAmount] = useState(0);
    const [donationType, setDonationType] = useState('');
    const [cardholderName, setCardholderName] = useState('');
    const [paymentIntent, setPaymentIntent] = useState(null);
    const location = useLocation();
    const [paymentError, setPaymentError] = useState(null);
    const [userData, setUserData] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    // useEffect(() => {
    //     const fetchClientSecret = async () => {
    //         const { data } = await axios.post('sys/stripepaymentintent', {
    //             amount: 1000,  // Pass amount here
    //         });
    //         setClientSecret(data.clientSecret);
    //     };
    //     fetchClientSecret();
    // }, []);

    const options = {
        clientSecret,  // Pass clientSecret to Elements
    };


    const handleSubmit = async (values) => {
            setLoading(true);
            const fetchClientSecret = async () => {
                const { data } = await axios.post('sys/stripepaymentintent', {
                    amount: (values["amount"]) * 100,  // Pass amount here
                    name: values["name"],
                    email: values["email"],
                    donation_type: values["donation_type"],

                });
                setUserData(values);
                setAmount((values["amount"]) * 100);
                setClientSecret(data.clientSecret);
                setLoading(false);
                setSubmitted(true);
            };
            fetchClientSecret();
    };

    return (
        <>
            <Card title={"Payment Collection"} >
                <Row gutter={24}>
                    <Col span={8} md={8} lg={8} sm={24} xs={24}></Col>
                    <Col span={8} md={8} lg={8} sm={24} xs={24}>
                        {
                            clientSecret && (
                                <Elements stripe={stripePromise} options={options}>
                                    <StripeCheckout amount={amount} userData={userData} />
                                </Elements>
                            )
                        }
                        {
                            !submitted ? <>
                                <Card>
                                    <Form layout={"vertical"} onFinish={handleSubmit}>
                                        <Row>
                                            <Col span={24}>
                                                <Form.Item
                                                    name="name"
                                                    label={"Name"}
                                                    rules={[{ required: true, message: 'Please input your name!' }]}
                                                >
                                                    <Input placeholder="Paid by (Name)" />
                                                </Form.Item>

                                                <Form.Item
                                                    name="email"
                                                    label={"Email"}
                                                    rules={[{ required: true, type: 'email', message: 'Please input a valid email!' }]}
                                                >
                                                    <Input placeholder="Email" />
                                                </Form.Item>

                                                <Form.Item
                                                    name="amount"
                                                    label={"Amount"}
                                                    rules={[{ required: true, message: 'Please enter an amount!' }]}
                                                >
                                                    <Input
                                                        placeholder="Enter an Amount"
                                                        type="number"
                                                        min="0"
                                                        onChange={(e) => setAmount(e.target.value)}
                                                    />
                                                </Form.Item>

                                                <Form.Item
                                                    name="donation_type"
                                                    label={"Donation Type"}
                                                    rules={[{ required: true, message: 'Please select a donation type!' }]}
                                                >
                                                    <Select
                                                        placeholder="Select Donation Type"
                                                    >
                                                        <Option value="tithes">Tithes</Option>
                                                        <Option value="offering">Offering</Option>
                                                        <Option value="missions">Missions</Option>
                                                        <Option value="ego_support">eGo Support</Option>
                                                    </Select>
                                                </Form.Item>

                                               <div style={{textAlign: 'center'}}>
                                                   <Button type="primary" htmlType="submit" loading={isLoading}>
                                                       Next
                                                   </Button>
                                               </div>

                                            </Col>
                                        </Row>
                                    </Form>
                                </Card>
                            </>:<></>
                        }
                    </Col>
                </Row>
            </Card>
        </>
    );
};

export default App;
