import { capFirstWordFirstLetter, capSentenceFirstLetter } from '@/util/CISUI';
import { css } from '@emotion/css';
import { Input as AntInput, Form } from 'antd';
import { useIntl } from 'react-intl';


export default function InputEmail({
  label = '',
  children,
  required = false,
  ...props
}) {
  const intl = useIntl();

  return (
    <Form.Item
      label={intl.formatMessage({
        id: capSentenceFirstLetter(label),
      })}
      name={props.name}
      rules={[
        {
          required: required,
          message: `Please enter ${label}`,
        },
        {
          type: 'email',
          message: intl.formatMessage({ id: 'Email is not valid' }),
        },
      ]}
      className={css`
      .ant-form-item-label{
        padding-bottom: 0!important;
      }
    `}
    >
      <AntInput
        {...props}
        className={`cis_input ${props.className}`}
        placeholder={intl.formatMessage({
          id: capFirstWordFirstLetter(props.placeholder || ''),
        })}
      >
        {children}
      </AntInput>
    </Form.Item>
  );
}
