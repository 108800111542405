import { capSentenceFirstLetter, dateTimeFormat } from '@/util/CISUI';
import { css } from '@emotion/css';
import { DatePicker as AntDatePicker, Form } from 'antd';
import { useIntl } from 'react-intl';
const { RangePicker } = AntDatePicker;

export default function InputRangePicker({
  label = '',
  required = false, showTime = true,
  ...props
}) {
  const intl = useIntl();
  return (
    <Form.Item
      name={props.name}
      rules={[
        {
          required: required,
          message: `Please select ${label}`,
        },
      ]}
      label={intl.formatMessage({
        id: capSentenceFirstLetter(label),
      })}
      className={css`
      .ant-form-item-label{
        padding-bottom: 0!important;
      }
    `}
    >
      {/* <AntDatePicker
        {...props}
        className={`cis_datepicker ${props.className}`}
        style={{ width: '100%' }}
        format={DATE_FORMAT}
        placeholder={intl.formatMessage({
          id: capFirstWordFirstLetter(props.placeholder || ''),
        })}
      /> */}
      <RangePicker
        {...props}
        // format="DD-MM-yyyy HH:mm:ss"
        format={dateTimeFormat}
        showTime={showTime}
        className={css`
          width: 100%;
        `}
        placeholder={["Start Date", "End Date"]}
      />
    </Form.Item>
  );
}
