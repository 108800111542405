import { capFirstWordFirstLetter, capSentenceFirstLetter, dateFormat } from '@/util/CISUI';
import { css } from '@emotion/css';
import { DatePicker as AntDatePicker, Form } from 'antd';
import { useIntl } from 'react-intl';

export default function InputDate({
  label = '',
  required = false,
  ...props
}) {
  const intl = useIntl();
  return (
    <Form.Item
      name={props.name}
      rules={[
        {
          required: required,
          message: `Please select ${label}`,
        },
      ]}
      label={intl.formatMessage({
        id: capSentenceFirstLetter(label),
      })}
      className={css`
      .ant-form-item-label{
        padding-bottom: 0!important;
      }
    `}
    >
      <AntDatePicker
        {...props}
        className={`cis_datepicker ${props.className}`}
        style={{ width: '100%' }}
        format={dateFormat}
        placeholder={intl.formatMessage({
          id: capFirstWordFirstLetter(props.placeholder || 'Select a date'),
        })}
      />
    </Form.Item>
  );
}
