import React from "react";
import { useSelector } from "react-redux";
import { Avatar, Popover } from "antd";

import { useHistory } from "react-router-dom";
import { useQueryClient } from "react-query";
import secureLocalStorage from "react-secure-storage";
import { TAB_SIZE } from "@/constants/ThemeSetting";
import { CisUI } from "@/util/CISUI";
import config from "@/util/config";

const UserInfo = () => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const width = useSelector(({ common }) => common.width);

  const logouthandler = () => {
    // localStorage.removeItem("token");
    // localStorage.removeItem("userData");
    secureLocalStorage.removeItem("token");
    secureLocalStorage.removeItem("userData");
    history.push("/signin");
    queryClient.removeQueries();
  };

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li>My Account</li>
      <li>Connections</li>
      {/* <li><Link to="/changelayout" style={{ color: "#545454" }} >Change Lauout</Link></li> */}
      {width <= TAB_SIZE && <li>Help</li>}
      <li onClick={logouthandler}>Logout</li>
    </ul>
  );

  let userImage = "https://via.placeholder.com/150";
  if (CisUI().getUserInfo("image") !== "") {
    userImage = config.imageURL + "user/" + CisUI().getUserInfo("image");
  }

  return (
    <Popover
      overlayClassName="gx-popover-horizantal"
      placement="bottomRight"
      style={{ textAlign: "center" }}
      content={userMenuOptions}
      trigger="click"
    >
      <Avatar src={userImage} className="gx-avatar gx-pointer" alt="" />
      {width >= TAB_SIZE && (
        <span className="username">{CisUI().getUserInfo("name")}</span>
      )}
    </Popover>
  );
};

export default UserInfo;
