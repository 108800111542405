// const hostAddress = "http://localhost:3000/";
const hostAddress = "https://echurch.mycop.church/";
const serverIP = "https://echurchapi.mycop.church";
// const serverIP = "https://trainingapi.mycop.church/";
// const serverIP = "http://127.0.0.1:8000";

module.exports = {
  BASE_URL: serverIP+ "/",
  API_URL: serverIP+ "/api",
  MEDIA_URL: serverIP,
  copyright: `© ${new Date().getFullYear()} The Church of Pentecost – eChurch Global. All Rights Reserved`,
  version: "1.0.0",
  DATE_FORMAT: 'YYYY-MM-DD',
  hostAddress: hostAddress,
  stripAPIKey: "pk_live_51Q85Qg02PTvaQf8yawB1RUHfEXqPxKpCbTCCEp03rlrqwKvtVNaiuIvYQ3zXnVEP1OhzuhxyRjub4JwUTSrK9vLH006m1A1pIK",
  zoomAPIKey: "ahzx0NNnQMmS6E7zQxm5ww",
  zoomAPISecret: "lwtwimpg2ZonstbejdNfYk5uv3yHgEjO",
  zoomRedirectURL: hostAddress+"zoom.html",
  zoomuserID: "echurch@copusa.org"
};

