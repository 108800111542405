// ALL COMMON API ROUTES
export const COMMONURL = {
  USERMENU: "sys/usermenu",
  ALLREGION: "sys/allregion",
  ALLDISTRICT: "sys/alldistrict",
  ALLLOCAL: "sys/alllocal",
  COUNTRIES: "sys/countries",
  USERPOSITIONS: "sys/userpositions",
  COMPANYAREA: "sys/companyarea",
  ALLCOMPANY: "sys/allcompany",
  ALLACCOUNT: "sys/allaccount",
  ETHNICITIES: "/sys/ethnicities",
  NATIONS: "sys/nations",
  USERROLES: "user/userroles",
  WAREHOUSE: "sys/allwarehouse",
  BRANCH: "sys/allbranch",
  FUNDPROGRAM: "sys/fundprogram",
  FUNCEXPENSE: "sys/funcexpense",
  RESTTYPE: "sys/resttype",
  PAYMENTSOURCE: "sys/paymentsource",
  ALLBENEFICIARY: "sys/beneficiary",
  ALLPAYMENTMETHOD: "sys/paymentmethod",
  FILEUPLOAD: "/sys/fileupload",
  VENDORTYPES: "/vendor/types",
  ALLMEMBERS: "/member/memberlist",
  RELATIONS: "/sys/relations",
};
