import React, { useState, useEffect } from 'react';
import {Form, Input, Button, message, Select, Row, Col, Card} from 'antd';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from "@/util/Api";
import config from "@/util/config";
import {useLocation} from "react-router-dom";

const { Option } = Select;


const PaymentForm = ({amount = 0, userData = null}) => {
    const stripe = useStripe();
    const elements = useElements();
    const [clientSecret, setClientSecret] = useState('');
    const [loading, setLoading] = useState(false);
    const [donationType, setDonationType] = useState('');
    const [cardholderName, setCardholderName] = useState('');
    const [paymentIntent, setPaymentIntent] = useState(null);
    const location = useLocation();
    const [paymentError, setPaymentError] = useState(null);
    const [paymentSuccess, setPaymentSuccess] = useState(null);

    // Fetch clientSecret as soon as the component mounts
    useEffect(() => {
        const fetchClientSecret = async () => {
            try {
                const { data } = await axios.post('sys/stripepaymentintent', {
                    amount: amount * 100, // Convert to cents
                });
                setClientSecret(data.clientSecret);
            } catch (error) {
                message.error('Failed to initialize payment. Please try again.');
            }
        };

        if (amount > 0) {
            fetchClientSecret();
        }
    }, [amount]);

    const handleSubmit = async (values) => {
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            return;
        }

        // Attempt to confirm the payment
        const ReturnURl = config.hostAddress+"paymentconfirm.html";
        const { error, paymentIntent } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                payment_method_data: {
                    billing_details: values,
                },
                return_url: ReturnURl,
            },
        });

        if (error) {
            // Show error to your customer (e.g., insufficient funds)
            setPaymentError(error.message);
            setPaymentSuccess(null);
        } else if (paymentIntent) {
            // Payment was successful, handle success
            setPaymentSuccess("Payment successful!");
            setPaymentError(null);

            // Optionally, you could also update the UI or redirect to another page
            // resetForm(); // Implement a form reset if necessary
        }
    };


    const paymentElementOptions = {
        layout: 'tabs',
        paymentMethodTypes: ['all']
    };



    return (
        <>
            <Row>
                <Col span={24}>
                    <Card>
                        <Form layout={"vertical"} onFinish={handleSubmit}>
                            <Row>
                                <Col span={24}>
                                    <div>Amount</div>
                                    <div style={{padding: '10px', marginTop: '10px', marginBottom: '10px', borderRadius: '10px', textAlign: 'center', fontWeight: "bold", fontSize: '25px', border: '1px solid #d5d5d5'}}>${amount/100}</div>
                                    {clientSecret && <PaymentElement options={paymentElementOptions} />}
                                    <div style={{padding: '20px', textAlign: 'center'}}>
                                        <Button type="primary" htmlType="submit" loading={loading} disabled={!stripe || !clientSecret}>
                                            Pay Now
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default PaymentForm;
