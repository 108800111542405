import { Checkbox, Form } from "antd";
import React from "react";
import { useIntl } from "react-intl";

const InputCheckbox = ({
  error_msg = "",
  required = true,
  label = "",
  checked = false,
  label_value = "",
  ...arg
}) => {
  const intl = useIntl();
  return (
    <>
      <Form.Item
        valuePropName="checked"
        rules={[{ required: required, message: error_msg }]}
        label={label === "" ? "" : intl.formatMessage({ id: label })}
        {...arg}
      >
        <Checkbox checked={checked}>
          {label_value === "" ? "" : intl.formatMessage({ id: label_value })}
        </Checkbox>
      </Form.Item>
    </>
  );
};

export default InputCheckbox;
