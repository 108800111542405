import { Button, Card, Collapse, Form, Popconfirm, Table } from "antd";
import ActionMenu from "components/Actions/Action";
import ListPrintBtn from "components/Forms/ListPrintBtn";
import Loader from "components/Loader";
import moment from "moment";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import axiosinstance from "util/Api";
import {
    CisUI,
    GetOPerationStatusTag,
    getQueryStrings,
    GetUserInfo,
    perPage,
    SearchObject,
} from "util/CISUI";
import IntlMessages from "util/IntlMessages";
import Config from "util/config";
import jsonFile from "./data.json";
import { useHistory } from "react-router-dom";
import { AdvSearch } from "util/AdvSearch";

const { Panel } = Collapse;

const PaymentHistory = () => {
    const [form] = Form.useForm();
    const [state, setState] = useState({
        reqURL: jsonFile.urls.list + window.location.search,
    });
    // const [data, setData] = useState([]);
    const history = useHistory();
    // const token = sLocalStorage.getItem("token");

    useEffect(() => {
        if (window.location.search) {
            setState({
                reqURL: jsonFile?.urls?.list + window.location.search,
            });
            let newObj = SearchObject(window.location.search);

            form.setFieldsValue(newObj);
        }
    }, [form]);

    const columns = [
        {
            title: "SL ID",
            dataIndex: "id",
            key: "id",
            sorter: (a, b) => a.id - b.id,
            render: (id, record, index) => {
                ++index;
                return index;
            },
            showSorterTooltip: false,
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
        },
        {
            title: "Date",
            dataIndex: "created_at",
            key: "created_at",
            render: (text) => moment(text).format("MMM DD, YYYY"),
        },
        {
            title: "Trax#",
            dataIndex: "payment_intent_id",
            key: "payment_intent_id"
        },
        {
            title: "Amount",
            dataIndex: "amount",
            key: "amount",
            align: 'right',
            render: (text) => CisUI().getCurrencyFormated1(text),
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
        }
    ];


    const { data: userData, isLoading } = useQuery([state.reqURL], () =>
        axiosinstance.get(state.reqURL).then((res) => res.data.data)
    );

    // if (isLoading) {
    //     return <Loader />
    // }
    // if (isError) {
    //     return <ServerError />
    // }

    const onFinish = (values) => {
        // console.log('Received values of form: ', values);
        console.log("Received values of form: ", values);
        let data = {
            ...values,
            // date: values['date'] ? values['date'].format('YYYY-MM-DD') : ""
            dates: values["dates"]
                ? [
                    values["dates"][0].format("YYYY-MM-DD"),
                    values["dates"][1].format("YYYY-MM-DD"),
                ]
                : "",
        };
        const qsup = getQueryStrings(data);
        console.log(qsup);
        history.push("?" + qsup);
        // axios.get(newURL, {
        //     headers: {
        //         'Content-Type': 'application/json',
        //         'Authorization': token,
        //     }
        // })
        //     .then((res) => {
        //         // console.log(res.data);
        //         if (res.data.status === 1) {
        //             setData(res.data.data);
        //             history.push("?" + qsup)
        //         }
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //     });
        // console.log("after", data)
    };

    // const DepartmentListAction = (
    //     <>
    //         {CisUI().listAction("../"+jsonFile.urls.add, "", state.reqURL, "data", 'asset_type', jsonFile, "", 'portrait')}
    //     </>
    // );


    const onReset = () => {
        form.resetFields();
        history.push("/" + jsonFile.urls.list);
    };

    const advanceInput = [];
    jsonFile?.input_fields?.forEach((item) => {
        if (item?.input) {
            const data = item.input.filter((i) => {
                return i.isFilterable;
            });
            advanceInput.push(...data);
        }
    });

    return (
        <>
            <Collapse accordion expandIconPosition="end" defaultActiveKey={["1"]}>
                <Panel
                    header={<IntlMessages id="Advance Search" />}
                    key="1"
                    className="avd__search"
                >
                    <Form
                        form={form}
                        onFinish={onFinish}
                        className="chms_form"
                        onReset={onReset}
                        layout="vertical"
                    >
                        {/*{AdvancedSearch(jsonFile.inputs,jsonFile.urls.list)}*/}
                        {AdvSearch(advanceInput)}
                    </Form>
                </Panel>
            </Collapse>

            <Card
                title={jsonFile.listtitle}
                className="chms__table"
            >
                <Table
                    size="small"
                    //   className="table"
                    scroll={{ x: true }}
                    columns={columns}
                    dataSource={userData}
                    bordered
                    pagination={{
                        position: ["bottomCenter"],
                        pageSize: perPage,
                        showSizeChanger: false,
                    }}
                    loading={{ spinning: isLoading, indicator: <Loader /> }}
                />
            </Card>
        </>
    );
};
export default PaymentHistory;
