import React, { useEffect, useRef, useState } from "react";
import { Button, Layout, Popover, Card, message, Avatar } from "antd";
import { Link, useHistory } from "react-router-dom";
import { toggleCollapsedSideNav } from "@/appRedux/actions";
import SearchBox from "@/components/SearchBox";
import UserInfo from "@/components/UserInfo";
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE,
} from "@/constants/ThemeSetting";
import { useDispatch, useSelector } from "react-redux";
import Auxiliary from "@/util/Auxiliary";
import ThemeSetting from "./ThemeSetting";
import AxiosInstance from "@/util/Api";
import { LogoutOutlined, MessageOutlined, UserOutlined } from "@ant-design/icons";
import config from "@/util/config";
import secureLocalStorage from "react-secure-storage";
import { useQueryClient } from "react-query";

const { Header } = Layout;

const Topbar = () => {
  const { navStyle, topBarColor } = useSelector(
    ({ settings }) => settings
  );
  const navCollapsed = useSelector(({ common }) => common.navCollapsed);
  const width = useSelector(({ common }) => common.width);
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();
  const { user } = useSelector(({ auth }) => auth);
  const queryClient = useQueryClient();
  const history = useHistory();

  // const languageMenu = () => (
  //   <CustomScrollbars className="gx-popover-lang-scroll">
  //     <ul className="gx-sub-popover">
  //       {languageData.map((language) => (
  //         <li
  //           className="gx-media gx-pointer"
  //           key={JSON.stringify(language)}
  //           onClick={() => dispatch(switchLanguage(language))}
  //         >
  //           <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`} />
  //           <span className="gx-language-text">{language.name}</span>
  //         </li>
  //       ))}
  //     </ul>
  //   </CustomScrollbars>
  // );

  const updateSearchChatUser = (evt) => {
    setSearchText(evt.target.value);
  };

  const logouthandler = () => {
    // localStorage.removeItem("token");
    // localStorage.removeItem("userData");
    secureLocalStorage.removeItem("token");
    secureLocalStorage.removeItem("userData");
    secureLocalStorage.removeItem("permissions");
    history.push("/signin");
    queryClient.removeQueries();
  };

  return (
    <>
      <Header className="top_header">
        <div className="header_company">
          <h2 className="tw-text-[#bfc434] tw-ml-12 tw-w-[300px] tw-hidden md:tw-block">
            {user?.company || ""}
          </h2>
        </div>
        <div className="header_shortcut">
          <Popover content={<ThemeSetting />} style={{display: "none"}} trigger="click">
            <Button
              type="link"
              icon={
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  width="18"
                  height="16"
                  xmlns="http://www.w3.org/2000/svg"
                  className=""
                >
                  <path
                    fill="none"
                    stroke-miterlimit="10"
                    stroke-width="32"
                    d="M430.11 347.9c-6.6-6.1-16.3-7.6-24.6-9-11.5-1.9-15.9-4-22.6-10-14.3-12.7-14.3-31.1 0-43.8l30.3-26.9c46.4-41 46.4-108.2 0-149.2-34.2-30.1-80.1-45-127.8-45-55.7 0-113.9 20.3-158.8 60.1-83.5 73.8-83.5 194.7 0 268.5 41.5 36.7 97.5 55 152.9 55.4h1.7c55.4 0 110-17.9 148.8-52.4 14.4-12.7 11.99-36.6.1-47.7z"
                  ></path>
                  <circle cx="144" cy="208" r="32"></circle>
                  <circle cx="152" cy="311" r="32"></circle>
                  <circle cx="224" cy="144" r="32"></circle>
                  <circle cx="256" cy="367" r="48"></circle>
                  <circle cx="328" cy="144" r="32"></circle>
                </svg>
              }
              className="tw-flex tw-items-center tw-text-heading"
              style={{display: 'none'}}
            >
              {width <= TAB_SIZE ? null : (
                <span className="tw-hidden md:tw-block" style={{display: 'none'}}>Theme Settings</span>
              )}
            </Button>
          </Popover>
          <Button
              type="link"
              className="tw-flex shortcurtIcone tw-items-center tw-text-heading"
          >
            {width <= TAB_SIZE ? null : (
                <Link to={"#"}>
                  <span className="tw-hidden md:tw-block">
                    <img src={"/assets/images/icons/facebook.png"} style={{width: '24px'}} alt={"Facebook"} />
                  </span>
                </Link>
            )}
          </Button>
          <Button
              type="link"
              className="tw-flex shortcurtIcone tw-items-center tw-text-heading"
          >
            {width <= TAB_SIZE ? null : (
                <Link to={"#"}>
                  <span className="tw-hidden md:tw-block">
                    <img src={"/assets/images/icons/instagram.png"} style={{width: '24px'}} alt={"Instagram"} />
                  </span>
                </Link>
            )}
          </Button>
          <Button
              type="link"
              className="tw-flex shortcurtIcone tw-items-center tw-text-heading"
          >
            {width <= TAB_SIZE ? null : (
                <Link to={"#"}>
                  <span className="tw-hidden md:tw-block">
                    <img src={"/assets/images/icons/tik-tok.png"} style={{width: '24px'}} alt={"TikTok"} />
                  </span>
                </Link>
            )}
          </Button>
          <Button
              type="link"
              className="tw-flex tw-items-center tw-text-heading"
          >
            {width <= TAB_SIZE ? null : (
                <Link to={"/video/youtube"}>
                  <span className="tw-hidden md:tw-block">
                    <img src={"/assets/images/youtube.png"} style={{width: "60px"}} alt={"YouTube"} />
                  </span>
                </Link>
            )}
          </Button>
          <Button
              type="link"
              className="tw-flex tw-items-center tw-text-heading"
          >
            {width <= TAB_SIZE ? null : (
                <span className="tw-hidden md:tw-block">
                  <img src={"/assets/images/logo.png"} style={{width: "30px"}} alt={"My eChurch"} /> My eChurch
                </span>
            )}
          </Button>
          <Button
            type="link"
            icon={
              <MessageOutlined />}
            className="tw-flex tw-items-center tw-text-heading"
          >
            {width <= TAB_SIZE ? null : (
              <span className="tw-hidden md:tw-block" style={{ margin: '0' }}> FAQ</span>
            )}
          </Button>
          <Button
            type="link"
            icon={
              <svg
                width="12"
                height="13"
                viewBox="0 0 12 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.97 10.1C6.18 10.1 6.3576 10.0274 6.5028 9.8822C6.648 9.737 6.7204 9.5596 6.72 9.35C6.72 9.14 6.6476 8.9624 6.5028 8.8172C6.358 8.672 6.1804 8.5996 5.97 8.6C5.76 8.6 5.5826 8.6726 5.4378 8.8178C5.293 8.963 5.2204 9.1404 5.22 9.35C5.22 9.56 5.2926 9.7376 5.4378 9.8828C5.583 10.028 5.7604 10.1004 5.97 10.1ZM5.43 7.79H6.54C6.54 7.46 6.5776 7.2 6.6528 7.01C6.728 6.82 6.9404 6.56 7.29 6.23C7.55 5.97 7.755 5.7224 7.905 5.4872C8.055 5.252 8.13 4.9696 8.13 4.64C8.13 4.08 7.925 3.65 7.515 3.35C7.105 3.05 6.62 2.9 6.06 2.9C5.49 2.9 5.0274 3.05 4.6722 3.35C4.317 3.65 4.0696 4.01 3.93 4.43L4.92 4.82C4.97 4.64 5.0826 4.445 5.2578 4.235C5.433 4.025 5.7004 3.92 6.06 3.92C6.38 3.92 6.62 4.0076 6.78 4.1828C6.94 4.358 7.02 4.5504 7.02 4.76C7.02 4.96 6.96 5.1476 6.84 5.3228C6.72 5.498 6.57 5.6604 6.39 5.81C5.95 6.2 5.68 6.495 5.58 6.695C5.48 6.895 5.43 7.26 5.43 7.79ZM6 12.5C5.17 12.5 4.39 12.3424 3.66 12.0272C2.93 11.712 2.295 11.2846 1.755 10.745C1.215 10.205 0.7876 9.57 0.4728 8.84C0.158 8.11 0.0004 7.33 0 6.5C0 5.67 0.1576 4.89 0.4728 4.16C0.788 3.43 1.2154 2.795 1.755 2.255C2.295 1.715 2.93 1.2876 3.66 0.9728C4.39 0.658 5.17 0.5004 6 0.5C6.83 0.5 7.61 0.6576 8.34 0.9728C9.07 1.288 9.705 1.7154 10.245 2.255C10.785 2.795 11.2126 3.43 11.5278 4.16C11.843 4.89 12.0004 5.67 12 6.5C12 7.33 11.8424 8.11 11.5272 8.84C11.212 9.57 10.7846 10.205 10.245 10.745C9.705 11.285 9.07 11.7126 8.34 12.0278C7.61 12.343 6.83 12.5004 6 12.5ZM6 11.3C7.34 11.3 8.475 10.835 9.405 9.905C10.335 8.975 10.8 7.84 10.8 6.5C10.8 5.16 10.335 4.025 9.405 3.095C8.475 2.165 7.34 1.7 6 1.7C4.66 1.7 3.525 2.165 2.595 3.095C1.665 4.025 1.2 5.16 1.2 6.5C1.2 7.84 1.665 8.975 2.595 9.905C3.525 10.835 4.66 11.3 6 11.3Z"
                  fill="#363636"
                />
              </svg>
            }
            className="tw-flex tw-items-center tw-text-heading"
          >
            {width <= TAB_SIZE ? null : (
              <span className="tw-hidden md:tw-block"> Help</span>
            )}
          </Button>
        </div>
      </Header>

      <Header
        style={{
          background: topBarColor,
        }}
      >
        {navStyle === NAV_STYLE_DRAWER ||
        ((navStyle === NAV_STYLE_FIXED ||
          navStyle === NAV_STYLE_MINI_SIDEBAR) &&
          width < TAB_SIZE) ? (
          <div className="gx-linebar gx-mr-3">
            <i
              className="gx-icon-btn icon icon-menu"
              style={{ color: "#fff" }}
              onClick={() => {
                dispatch(toggleCollapsedSideNav(!navCollapsed));
              }}
            />
          </div>
        ) : null}
        <Link to="/dashboard" className="gx-d-block gx-d-lg-none gx-pointer">
          <img
            alt="echurch"
            // className="medsuite_logo_four"
            style={{ width: "200px" }}
            src={"/assets/images/echurch_logo.png"}
          />
        </Link>

        {/* <SearchBox
          styleName="gx-d-none gx-d-lg-block gx-lt-icon-search-bar-lg"
          placeholder="Search by Member Name... Test"
          onChange={updateSearchChatUser}
          value={searchText}
        /> */}
        <GlobalSearch/>
        <ul className="gx-header-notifications gx-ml-auto">
          <li className="gx-notify gx-notify-search gx-d-inline-block gx-d-lg-none">
            <Popover
              overlayClassName="gx-popover-horizantal"
              placement="bottomRight"
              content={
                <SearchBox
                  styleName="gx-popover-search-bar"
                  placeholder="Search by Member Name..."
                  onChange={updateSearchChatUser}
                  value={searchText}
                />
              }
              trigger="click"
            >
              <span className="gx-pointer gx-d-block">
                <i className="icon icon-search-new" style={{ color: "#fff" }} />
              </span>
            </Popover>
          </li>
          {width >= TAB_SIZE ? null : (
            <Auxiliary>
              {/* <li className="gx-notify">
                <Popover
                  overlayClassName="gx-popover-horizantal"
                  placement="bottomRight"
                  content={<AppNotification />}
                  trigger="click"
                >
                  <span className="gx-pointer gx-d-block">
                    <i className="icon icon-notification" />
                  </span>
                </Popover>
              </li> */}

              {/* <li className="gx-msg">
                <Popover
                  overlayClassName="gx-popover-horizantal"
                  placement="bottomRight"
                  content={<MailNotification />}
                  trigger="click"
                >
                  <span className="gx-pointer gx-status-pos gx-d-block">
                    <i className="icon icon-chat-new" />
                    <span className="gx-status gx-status-rtl gx-small gx-orange" />
                  </span>
                </Popover>
              </li> */}
            </Auxiliary>
          )}
          {/* <li className="gx-language">
            <Popover
              overlayClassName="gx-popover-horizantal"
              placement="bottomRight"
              content={languageMenu()}
              trigger="click"
            >
              <span className="gx-pointer gx-flex-row gx-align-items-center">
                <i className={`flag flag-24 flag-${locale.icon}`} />
                <span className="gx-pl-2 gx-language-name">{locale.name}</span>
                <i className="icon icon-chevron-down gx-pl-2" />
              </span>
            </Popover>
          </li> */}
          {width >= TAB_SIZE ? null : (
            <Auxiliary>
              <li className="gx-user-nav">
                <UserInfo />
              </li>
            </Auxiliary>
          )}
        </ul>
        <div
        style={{
          color: "white",
          display: "flex",
          alignTtems: "center",
          columnGap: "8px",
          cursor: 'pointer'
        }}
        onClick={logouthandler}
        >
          <LogoutOutlined />
          <span>Logout</span>
        </div>
      </Header>
    </>
  );
};

export default Topbar;

const GlobalSearch = () => {
  const [members, setMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { Meta } = Card;
  // const [searchText, setSearchText] = useState("");
  const searchResultsRef = useRef(null);

  const debounceHandler = (fn, delay) => {
    let timeoutId;
    return (...args) => {
      // const [event] = args;
      // setSearchText(event.target.value);
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        fn(...args);
      }, delay);
    };
  };

  const doSearch = (e) => {
    console.log("🚀 ~ doSearch ~ e:", e);
    const inputValue = e.target.value.trim();
    setIsLoading(true);
    if (inputValue === "") {
      setIsLoading(false);
      return;
    }

    const onlySpaces = /^\s+$/.test(inputValue);

    if (onlySpaces) {
      setIsLoading(false);
      return;
    }

    AxiosInstance.get(`member/globalsearch?param=${inputValue}`).then((res) => {
      const members = res.data.data;
      setMembers(members);
      if (members.length === 0) {
        message.warning("No member found");
      }
      if (res.data.status === 1) {
        setIsLoading(false);
      } else {
        setIsLoading(false);
        message.error(res.data.msg);
      }
    });
  };

  const handleSearch = debounceHandler(doSearch, 1000);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (
        searchResultsRef.current &&
        !searchResultsRef.current.contains(e.target)
      ) {
        setMembers([]); // Close the members list on outside click
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <>
      <div
        style={{
          position: "relative",
        }}
        className="header-search"
      >
        <div
          style={{
            display: "flex",
          }}
        >
          <SearchBox
            styleName="gx-lt-icon-search-bar-lg"
            placeholder="Enter Member Name/Email/Phone/MemberID..."
            onChange={handleSearch}
            // value={searchText}

          />
          {/* <Button
            // type="primary"
            icon={<SearchOutlined />}
            style={{
              borderRadius: "0 4px 4px 0",
              marginBottom: 0,
              // background: "#bfc434",
            }}
            loading={isLoading}
            size="large"
          >
            Search
          </Button> */}
        </div>

        <div
          style={{
            position: "absolute",
            width: "100%",
            display: { members: members?.length > 0 ? "block" : "none" },
          }}
          ref={searchResultsRef}
          className="header-search-result"
        >
          {members?.slice(0, 5)?.map((member) => (
            <Link
              to={`/member/memberdetails/${member?.id}`}
              className="header-search-item"
            >
              {/* <Image
                src="/assets/images/coplogo.png"
                width={50}
                height={50}
                preview={false}
              /> */}
              {member?.image ? (
                <Avatar
                  src={config.BASE_URL + member?.image}
                  size={40}
                  shape="circle"
                />
              ) : (
                <Avatar size={40} shape="circle" icon={<UserOutlined />} />
              )}
              <Meta
                title={member?.name}
                description={`ID: ${member?.unq_id} ${
                  member?.email ? `Email: ${member?.email}` : ""
                }`}
              />
            </Link>
          ))}
        </div>
      </div>
    </>
  );
};
