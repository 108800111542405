import { INIT_URL, SET_AUTH_USER } from "@/constants/ActionTypes";


export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export function setAuthData(data) {
  return (dispatch) => {
    dispatch({ type: SET_AUTH_USER, payload: data });
  };
}