import { capFirstWordFirstLetter, capSentenceFirstLetter } from '@/util/CISUI';
import { css } from '@emotion/css';
import { Form, Select as AntSelect } from 'antd';
import { useIntl } from 'react-intl';


const InputSelect = ({
  label = '',
  items = [],
  required = false,
  ...props
}) => {
  const intl = useIntl();
  return (
    <Form.Item
      label={capSentenceFirstLetter(label)}
      name={props.name}
      rules={[
        {
          required: required,
          message: intl.formatMessage({
            id: `Please select ${capSentenceFirstLetter(label)}`,
          }),
        },
      ]}
      initialValue={props.initialValue}
      className={css`
      .ant-form-item-label{
        padding-bottom: 0!important;
      }
    `}
    >
      <AntSelect
        {...props}
        className={`cis_select ${props.className}`}
        placeholder={intl.formatMessage({
          id: capFirstWordFirstLetter(props.placeholder || 'Select an option'),
        })}
        showSearch
        allowClear
        loading={props.isLoading}
      >
        {items?.map((item, index) => (
          <AntSelect.Option key={index} value={item.id}>
            {item.name}
          </AntSelect.Option>
        ))}
      </AntSelect>
    </Form.Item>
  );
};

export default InputSelect;
