import { capFirstWordFirstLetter, capSentenceFirstLetter } from '@/util/CISUI';
import { css } from '@emotion/css';
import { Input as AntInput, Form } from 'antd';


export default function InputGroup({
  label = '',
  children,
  required = false,
  isTextFormat = true,
  ...props
}) {

  return (
    <Form.Item
      name={props.name}
      rules={[
        {
          required: required,
          message: `Please enter ${isTextFormat ? capSentenceFirstLetter(label) : label}`,

        },
      ]}
      label={isTextFormat ? capSentenceFirstLetter(label) : label}
      className={css`
        .ant-form-item-label{
          padding-bottom: 0!important;
        }
      `}
    >
      <AntInput
        {...props}
        className={`cis_input ${props.className}`}
        placeholder={isTextFormat
          ? capFirstWordFirstLetter(props.placeholder || '')
          : props.placeholder || ''
        }
      >
        {children}
      </AntInput>
    </Form.Item>
  );
}
