import React from 'react';
import { Result, Button } from 'antd';

const RegistrationSuccess = () => {
    const handleGoToLogin = () => {
        // Replace with your actual login route
        window.location.href = '/registration';
    };

    return (
        <Result
            status="success"
            title="Registration Successful!"
            subTitle="Thank you for registering with us. One of our executives will contact you shortly."
            extra={[
                <Button type="primary" key="login" onClick={handleGoToLogin}>
                    Go to Registration again
                </Button>,
            ]}
        />
    );
};

export default RegistrationSuccess;
