import { setSideBarColor, setTopBarColor } from "@/appRedux/actions";
import { useDispatch, useSelector } from "react-redux";

export default function ThemeSetting() {
  const { sideBarColor, topBarColor } = useSelector(({ settings }) => settings);

  const dispatch = useDispatch();

  const colors = ["#112c64","#023b68","#004a5f", "#000000", "#FFFFFF", "#464b53", "#bea05f"];
  return (
    <div className="tw-flex tw-items-start tw-flex-col tw-w-60">
      <p className="tw-mb-2 tw-text-heading">Sidebar Color: </p>
      <ul className="gx-color-option gx-list-inline">
        {colors.map((color) => (
          <li>
            <span
              onClick={() => dispatch(setSideBarColor(color))}
              style={{
                backgroundColor: color,
                color: color,
                width: 30,
                height: 30,
                borderRadius: 5,
                display: "block",
                cursor: "pointer",
                border: sideBarColor === color ? "2px solid #004a5f" : "none",
              }}
            >
              {sideBarColor === color && (
                <i className="icon icon-check" style={{ color: "#112c64" }}></i>
              )}
            </span>
          </li>
        ))}
      </ul>
      <p className="tw-mb-2 tw-text-heading">Topbar Color: </p>
      <ul className="gx-color-option gx-list-inline">
        {colors.map((color) => (
          <li>
            <span
              onClick={() => dispatch(setTopBarColor(color))}
              style={{
                backgroundColor: color,
                color: color,
                width: 30,
                height: 30,
                borderRadius: 5,
                display: "block",
                cursor: "pointer",
                border: topBarColor === color ? "2px solid #004a5f" : "none",
              }}
            >
              {topBarColor === color && (
                <i className="icon icon-check" style={{ color: "#112c64" }}></i>
              )}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
}
