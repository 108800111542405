import { headers } from "@/util/Api";
import { COMMONURL } from "@/util/ComminUrl";
import { API_URL } from "@/util/config";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, message, Modal, Upload } from "antd";
import React, { useState } from "react";

/**
 *  Multiple Upload Component for Multiple Image Upload
 *
 * @param {files} - array of files
 * @param {setFiles} - setFiles function
 * @param {source} - name of the source
 * @param {maxCount} - max count of files
 * @returns - JSX Element
 * @example - <MultipleUpload files={files} setFiles={setFiles} source="source" maxCount={2} />
 *
 */
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const SingleUpload = ({
  label = "Upload",
  placeholder = "",
  extra = "",
  name = "file",
  required = false,
  source = "sme",
  maxCount = 1,
  index,
}) => {
  const [file, setFile] = useState([]);
  const [files, setFiles] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [loading, setLoading] = useState(false);

  const normFile = (e) => {
    console.log("Hello there ", Array.isArray(e));
    if (Array.isArray(e)) {
      return file;
    }
    return file && e.fileList;
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const onChange = (event) => {
    console.log(
      "🚀 ~ file: FileUpload.js ~ line 194 ~ FileUpload ~ event",
      event
    );
    if (event.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (event.file.status === "done") {
      if (event.file.xhr.status === 200) {
        const { response } = event.file;
        console.log(
          "🚀 ~ file: FileUpload.js ~ line 201 ~ FileUpload ~ response",
          response
        );
        if (response) {
          if (response.status === 1) {
            setFiles([...files, response.data]);
            message.success(`${event.file.name} file uploaded successfully`);
          } else {
            message.error(`${event.file.name} file upload failed.`);
          }
        }
      }
      setLoading(false);
    } else if (event.file.status === "removed") {
      const { response } = event.file;
      if (response) {
        if (response.status === 1) {
          const index = files.findIndex(
            (item) => item.uid === response.data.uid
          );
          if (index > -1) {
            files.splice(index, 1);
            setFiles(files);
          }
        }
      }
    }
  };

  const onRemove = (event) => {
    const index = files.findIndex((file) => file.uid === event.uid);
    const newFileList = files.slice();
    newFileList.splice(index, 1);
    setFiles(newFileList);
  };

  const beforeUpload = (event) => {
    const isJpgOrPng =
      event.type === "image/jpg" ||
      event.type === "image/jpeg" ||
      event.type === "image/png" ||
      event.type === "application/pdf";

    if (!isJpgOrPng) {
      message.error("You can only upload jpg, jpeg, png and pdf file only!");
      return false;
    }
    if (files.length >= maxCount) {
      message.error(`You can only upload ${maxCount} file!`);
      return false;
    }
    if (event.size / 1024 / 1024 > 2) {
      message.error("File size must be less than 2MB!");
      return false;
    }
    setFile(event);
    return true;
  };

  return (
    <>
      <Form.Item
        name={index ? [index, name] : name}
        label={label}
        valuePropName="fileList"
        extra={extra}
        getValueFromEvent={normFile}
        rules={[{ required: required }]}
      >
        <Upload
          multiple={true}
          // listType="picture-card"
          maxCount={maxCount}
          action={API_URL + COMMONURL.FILEUPLOAD}
          headers={headers}
          method="POST"
          data={{
            file: file,
            source: source,
            file_id: file.uid,
          }}
          onPreview={handlePreview}
          onChange={onChange}
          onRemove={onRemove}
          beforeUpload={beforeUpload}
          // fileList={files}
          // itemRender={files}
        >
          {files.length >= maxCount || loading ? null : (
            <Button icon={<UploadOutlined />}> {placeholder} </Button>
          )}
        </Upload>
      </Form.Item>

      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={() => setPreviewOpen(false)}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
};

export default SingleUpload;
