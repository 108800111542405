/* eslint-disable react-hooks/exhaustive-deps */

import { Button, Col, Form, Image, Input, message, Row } from "antd";
import { Link, useHistory } from "react-router-dom";

import { UserOutlined } from "@ant-design/icons";
import AxiosInstance from "../util/Api";
import { useMutation } from "react-query";
import CircularProgress from "../components/CircularProgress";
import { useEffect } from "react";
import secureLocalStorage from "react-secure-storage";
import img from "../assets/images/login.png";
import { useDispatch } from "react-redux";
import { setAuthData } from "@/appRedux/actions";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (
      secureLocalStorage.getItem("token") &&
      secureLocalStorage.getItem("userData") &&
      secureLocalStorage.getItem("permissions")
    ) {
      history.push("/dashboard");
      dispatch(
        setAuthData({
          user: secureLocalStorage.getItem("userData"),
          token: secureLocalStorage.getItem("token"),
          permissions: secureLocalStorage.getItem("permissions"),
        })
      );
    }
  }, []);

  const singin = async (values) => {
    const { data: response } = await AxiosInstance.post("auth/login", values);
    return response;
  };

  const { mutate, isLoading } = useMutation(singin, {
    onSuccess: (data) => {
      if (data.status === 1) {
        message.success(data.msg);
        secureLocalStorage.setItem("token", data.token);
        secureLocalStorage.setItem("userData", data.user);
        secureLocalStorage.setItem("permissions", data.prms);
        history.push("/dashboard");
        dispatch(
          setAuthData({
            user: data.user,
            token: data.token,
            permissions: data.prms,
          })
        );
      } else if (data.status === 0) {
        message.warning(data.error);
      } else {
        message.error(data.msg);
      }
    },
    onError: () => {
      message.error("Server Error");
    },
  });

  const onFinish = (values) => {
    // mutate(values);
    history.push("/send-otp");
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Row className="signin" style={{ margin: "0" }}>
      <Col
        xs={24}
        lg={12}
        style={{ background: "#F0F8FD", marginRight: "auto" }}
      >
        <div className="signin__right" style={{ width: "80%", margin: "auto" }}>
          <div className="signin__right__logo">
            <img src="/assets/images/logo.svg" alt="SME" className="logo" />
          </div>
          <div className="signin__right__title">
            <h1>Forgot Password</h1>{" "}
            <p style={{ maxWidth: "380px" }}>
              Enter your registered email address, or username to change your account password.
            </p>
          </div>
          <Form
            className="signin__right__form"
            onFinish={onFinish}
            layout="vertical"
          >
            <Form.Item
              name="userid"
              label="Username or Email"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <Input
                placeholder="Username"
                prefix={<UserOutlined />}
                required={true}
                size="large"
              />
            </Form.Item>

            <div>
              <Button
                type="primary"
                htmlType="submit"
                block
                className="signin__right__form__submit tw-h-12"
                size="large"
                loading={isLoading}
              >
                Next
              </Button>
            </div>

            <Form.Item className="back__to__signin">
              <Link to="/signin">Back to Sign in</Link>
            </Form.Item>
          </Form>
        </div>
      </Col>

      <Col xs={0} lg={12} className="signin__left">
        <Image
          src={img}
          preview={false}
          alt="authentication"
          className="signin__left__image"
        />
        <div>
          <p
            style={{
              fontSize: "20px",
              fontStyle: "italic",
              color: "#152c5b",
            }}
          >
            Welcome to CHMS
          </p>
        </div>
      </Col>
    </Row>
  );
};

export default ForgotPassword;
