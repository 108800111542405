import { DotChartOutlined } from "@ant-design/icons";
import { Skeleton, Space } from "antd";
import React from "react";

const SidebarLoading = ({ isLoading }) => {
  return (
    <>
      <div className="sidebar_skeleton">
        <Space style={{ marginBottom: "20px" }}>
          <Skeleton active={isLoading} size="small" round paragraph={false} />
          <Skeleton.Node active={isLoading}>
            <DotChartOutlined
              style={{
                fontSize: 40,
                color: "#bfbfbf",
              }}
              shape="circle"
            />
          </Skeleton.Node>
        </Space>
        <Skeleton
          active={isLoading}
          size="small"
          round
          paragraph={false}
          block
        />
        <Skeleton
          active={isLoading}
          size="small"
          round
          paragraph={false}
          block
        />
        <Skeleton
          active={isLoading}
          size="small"
          round
          paragraph={false}
          block
        />
        <Skeleton
          active={isLoading}
          size="small"
          round
          paragraph={false}
          block
        />
        <Skeleton
          active={isLoading}
          size="small"
          round
          paragraph={false}
          block
        />
        <Skeleton
          active={isLoading}
          size="small"
          round
          paragraph={false}
          block
        />
        <Skeleton
          active={isLoading}
          size="small"
          round
          paragraph={false}
          block
        />
        <Skeleton
          active={isLoading}
          size="small"
          round
          paragraph={false}
          block
        />
        <Skeleton
          active={isLoading}
          size="small"
          round
          paragraph={false}
          block
        />
        <Skeleton
          active={isLoading}
          size="small"
          round
          paragraph={false}
          block
        />
        <Skeleton
          active={isLoading}
          size="small"
          round
          paragraph={false}
          block
        />
        <Skeleton
          active={isLoading}
          size="small"
          round
          paragraph={false}
          block
        />
        <Skeleton
          active={isLoading}
          size="small"
          round
          paragraph={false}
          block
        />
        <Skeleton
          active={isLoading}
          size="small"
          round
          paragraph={false}
          block
        />
        <Skeleton
          active={isLoading}
          size="small"
          round
          paragraph={false}
          block
        />
      </div>
    </>
  );
};

export default SidebarLoading;
