import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { useMutation } from "react-query";
import { Button, message, Popover } from "antd";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import axiosinstance from "util/Api";
import { RequestError } from "util/CISUI";

/**
 *
 * @param {string} editUrl - url to edit page
 * @param {string} recordID - id of record to be deleted
 * @param {string} deleteApiUrl - url to delete record
 * @param {string} refetchQuerie - name of query to be refetched after deletion
 * @param {string} detailsUrl - url to details page
 *
 */

const ActionMenu = ({
  editUrl,
  recordID,
  deleteApiUrl,
  refetchQuerie,
  detailsUrl = "",
  isDelete = true,
}) => {
  const [visible, setVisible] = useState(false);
  const [deletePopover, setDeletePopover] = useState(false);
  const history = useHistory();

  const deleteCompanyType = async (id) => {
    const { data: response } = await axiosinstance.delete(deleteApiUrl + id);
    return response;
  };

  const { mutate: deleteMutate, isLoading } = useMutation(deleteCompanyType, {
    onSuccess: (data) => {
      if (data.status === 1) {
        history.push(refetchQuerie);
        message.success(data.msg);
        setVisible(false);
        setDeletePopover(false);
      } else {
        message.error(data.msg);
      }
    },
    onError: () => {
      message.error(RequestError());
    },
  });

  const handleDelete = (id) => {
    deleteMutate(id);
  };
  const cancelDelete = () => {
    setVisible(false);
    setDeletePopover(false);
  };
  const handleDeleteOpen = (open) => {
    setDeletePopover(open);
  };

  const handleOpenChange = (open) => {
    setVisible(open);
  };

  return (
    <Popover
      open={visible}
      onOpenChange={handleOpenChange}
      overlayClassName="chms__action__popover"
      content={
        <>
          <Link to={editUrl + recordID}>
            <Button
              size="small"
              type="primary"
              block
              className="chms__action__popover__btn"
              icon={<EditOutlined />}
            >
              Edit
            </Button>
          </Link>

          {detailsUrl !== "" && (
            <Link to={detailsUrl + recordID}>
              <Button
                size="small"
                type="primary"
                block
                className="chms__action__popover__btn"
                icon={<EyeOutlined />}
              >
                Detail
              </Button>
            </Link>
          )}

          {/* We can use here Popconfirm also */}
          <Popover
            open={deletePopover}
            onOpenChange={handleDeleteOpen}
            placement="topLeft"
            trigger="click"
            content={
              <div className="chms__action__popover__content">
                <p>Are you sure you want to delete this record?</p>
                <div className="chms__action__popover__content__btns">
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => handleDelete(recordID)}
                    loading={isLoading}
                    disabled={isLoading}
                  >
                    Yes
                  </Button>
                  <Button size="small" onClick={cancelDelete}>
                    No
                  </Button>
                </div>
              </div>
            }
          >
            {isDelete && (
              <Button
                size="small"
                type="danger"
                block
                icon={<DeleteOutlined />}
                className="chms__action__popover__btn"
                onClick={() => setDeletePopover(true)}
              >
                Delete
              </Button>
            )}
          </Popover>
        </>
      }
      title="Actions"
      placement="left"
      trigger="click"
    >
      <Button
        onClick={() => setVisible(!visible)}
        size="small"
        style={{ marginBottom: 0 }}
      >
        <EllipsisOutlined />
      </Button>
    </Popover>
  );
};

export default ActionMenu;
