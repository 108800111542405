import { useState } from 'react';
import { Upload, Form, Image, notification, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
// import { capSentenceFirstLetter, getBase64 } from '@/utils/CISUI';
// import AxiosInstance from '@/util/Api';
import { useIntl } from 'react-intl';
import AxiosInstance from '@/util/Api';
import { capSentenceFirstLetter, getBase64 } from '@/util/CISUI';


const ImageUpload = ({ label = '', name, maxCount = 1 }) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileList, setFileList] = useState([]);
  const intl = useIntl();

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const props = {
    beforeUpload: async (file) => {
      const isMaxCount = fileList.length < maxCount;

      if (!isMaxCount) {
        notification.error({
          message: 'Error',
          description: `You can only upload ${maxCount} file only!`,
        });
        return Upload.LIST_IGNORE;
      }

      const isJpgOrPng =
        file.type === 'image/jpg' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/png';

      if (!isJpgOrPng) {
        notification.error({
          message: 'Error',
          description: 'You can only upload jpg, jpeg, png file only!',
        });
        return Upload.LIST_IGNORE;
      }

      const isLt2M = file.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        notification.error({
          message: 'Error',
          description: 'Please upload file smaller than 1MB!',
        });
        return Upload.LIST_IGNORE;
      }

      return false;
    },
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || (file.preview));
    setPreviewOpen(true);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const confirmDelete = (file) => {
    return new Promise((resolve, reject) => {
      Modal.confirm({
        title: 'Are you sure?',
        content: 'You want to remove this file?',
        async onOk() {
          if (file.url) {
            try {
              await AxiosInstance.delete(`common/filedelete/${file.uid}`);
              notification.success({
                message: 'Success',
                description: 'File removed successfully!',
              });
              console.log("file removed");
              resolve(true);
            } catch (error) {
              notification.error({
                message: 'Error',
                description: "File can't be removed",
              });
              resolve(false);
            }
          } else {
            setFileList((prevList) => prevList.filter((item) => item.uid !== file.uid));
            notification.success({
              message: 'Success',
              description: 'File removed successfully!',
            });
            console.log("file removed");
            resolve(true);
          }
        },
        onCancel() {
          console.log("file not removed");
          resolve(false);
        },
      });
    });
  };

  const onRemove = async (file) => {
    const result = await confirmDelete(file);
    if (result) {
      console.log("Confirmation completed: file removed");
      return true;
    } else {
      console.log("Confirmation completed: file not removed");
      return false
    }
  };

  return (
    <>
      <Form.Item
        name={name}
        valuePropName="fileList"
        getValueFromEvent={normFile}
        label={intl.formatMessage({
          id: capSentenceFirstLetter(label),
        })}
        extra={
          <span
            style={{
              color: 'gray',
              fontSize: '12px',
            }}
          >

            {maxCount > 1 && (
              <p>
                <b>Note: </b>
                <span>
                  Please upload up to {maxCount} files (JPG, JPEG, PNG) with a
                  maximum size of 1MB each
                </span>
              </p>
            )}

            {maxCount === 1 && (
              <p>
                <b>Note: </b>
                <span>
                  <>
                    Please upload file in (JPG, JPEG, PNG) format with a maximum
                    size of 1MB
                  </>
                </span>
              </p>
            )}
          </span>
        }
      >
        <Upload
          {...props}
          listType="picture-card"
          maxCount={maxCount}
          onPreview={handlePreview}
          fileList={fileList}
          onRemove={onRemove}
          onChange={handleChange}
        >
          {fileList.length >= maxCount ? null : uploadButton}
        </Upload>
      </Form.Item>

      {previewImage && (
        <Image
          wrapperStyle={{ display: 'none' }}
          preview={{
            visible: previewOpen,
            onVisibleChange: (visible) => setPreviewOpen(visible),
            afterOpenChange: (visible) => !visible && setPreviewImage(''),
          }}
          src={previewImage}
        />
      )}
    </>
  );
};

export default ImageUpload;
