import asyncComponent from "@/util/asyncComponent";
import React from "react";
import { Route, Switch } from "react-router-dom";
import NewDonation from "@/containers/Financial/NewDonation";
import PaymentHistory from "@/containers/Financial/PaymentHistory";

const Member = ({ match }) => (
    <Switch>
        <Route path={`${match.url}/newdonation`} component={NewDonation}/>
        <Route path={`${match.url}/donationstatement`} component={PaymentHistory}/>
    </Switch>
);

export default Member;
