import { useQuery } from 'react-query';
import Select from './InputSelect';
import AxiosInstance from '@/util/Api';

const SelectRequest = ({ label = '', path = '', ...props }) => {
  const { isLoading, data } = useQuery(
    [path],
    () => AxiosInstance.get(path).then((res) => res.data.data),
    { refetchOnMount: false, cacheTime: Infinity, staleTime: Infinity }
  );

  return (
    <Select
      items={data}
      label={label}
      isLoading={isLoading}
      required={props.required}
      allowClear
      filterOption={(input, option) => option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0}
      {...props}
    />
  );
};

export default SelectRequest;
