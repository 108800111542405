/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Menu } from "antd";
import { Link, useHistory } from "react-router-dom";
import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL
} from "../../constants/ThemeSetting";
import { useQuery, useQueryClient } from "react-query";
import axios from "axios";
import { API_URL } from "../../util/config";
import secureLocalStorage from "react-secure-storage";

const HorizontalNav = () => {
  const navStyle = useSelector(({ settings }) => settings.navStyle);
  // const pathname = useSelector(({ common }) => common.pathname);
  const history = useHistory();
  const queryClient = useQueryClient();
  // const location = useLocation();

  const [userMenus, setUserMenus] = useState([]);


  const getNavStyleSubMenuClass = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return "gx-menu-horizontal gx-submenu-popup-curve";
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-inside-submenu-popup-curve";
      case NAV_STYLE_BELOW_HEADER:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-below-submenu-popup-curve";
      case NAV_STYLE_ABOVE_HEADER:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-above-submenu-popup-curve";
      default:
        return "gx-menu-horizontal";
    }
  };

  const { data, isLoading } = useQuery(['usermenu'], () => axios.post(`${API_URL}/sys/usermenu`, null, { headers: { Authorization: secureLocalStorage.getItem("token") } }).then((res) => res.data.data
  ), { cacheTime: Infinity, staleTime: Infinity });

  const logouthandler = () => {
    // localStorage.removeItem("token");
    // localStorage.removeItem("userData");
    secureLocalStorage.removeItem("token");
    secureLocalStorage.removeItem("userData");

    history.push("/signin");
    queryClient.removeQueries()
  }

  useEffect(() => {

    if (data) {
      const userMenu = data.map(item => {
        return {
          label: item.label,
          key: item.id + item.urlkey,
          icon: <i className={item.icon} />,
          children: item?.submodule?.map(child => {
            return {
              // label: child.label,
              label: child?.menu.length === 0 ? <Link to={`/${item.urlkey}/${child.urlkey}`}>{child.label}</Link> : child.label,
              key: child.id + child.urlkey,
              // icon: child?.menu.length === 0 ? <i className={child.icon} /> : null,
              popupClassName: getNavStyleSubMenuClass(navStyle),
              children: child?.menu.length !== 0 ? child?.menu?.map(child2 => {
                return {
                  label: <Link to={`/${item.urlkey}/${child2.urlkey}`}>{child2.label}</Link>,
                  key: child2.id + child2.urlkey,
                  // icon: <i className={child2.icon} />,
                }
              }) : null
            }
          })
        }
      });

      userMenu.unshift({ label: <Link to="/dashboard">Dashboard</Link>, key: `/dashboard`, icon: <i className="icon icon-dasbhoard" /> })
      userMenu.push({ label: <span onClick={logouthandler}>Logout</span>, key: `777_logout`, icon: <i className="icon icon-signin" /> })

      setUserMenus(userMenu);

    }
  }, [data]);

  if (isLoading) {
    <h1>Loading...</h1>
  }
  return (
    <>
      <Menu
        mode="horizontal"
        items={userMenus} />
    </>
  );
};

HorizontalNav.propTypes = {};

export default HorizontalNav;

