import { SET_AUTH_USER } from "@/constants/ActionTypes";

const INIT_STATE = {
  user: undefined,
  token: undefined,
  permissions: undefined,
};

const UserReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_AUTH_USER: {
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        permissions: action.payload.permissions,
      };
    }
    default:
      return state;
  }
};

export default UserReducer;
