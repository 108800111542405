import { ReloadOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Col, Collapse, Row } from "antd";
import IntlMessages from "./IntlMessages";
import { InputField } from "./InputField";

export const AvdWrapper = ({ children }) => {
  return (
    <Collapse accordion expandIconPosition="right" defaultActiveKey={["1"]}>
      <Collapse.Panel
        header={<IntlMessages id="Advance Search" />}
        key="1"
        className="avd__search"
      >
        {children}
      </Collapse.Panel>
    </Collapse>
  );
};

export const AdvSearch = (input) => {
  return (
    <Row gutter={[24, 0]}>
      <Col span={24} lg={21} md={21}>
        <Row>
          <InputField input={input} type="adv" />
        </Row>
      </Col>
      <Col span={24} lg={3} md={3} className="adv_submit">
        <Button type="primary" htmlType="submit" style={{
          marginBottom: 0
        }}>
          <SearchOutlined />
        </Button>
        <Button icon={<ReloadOutlined />} htmlType="reset" style={{
          marginBottom: 0
        }}></Button>
      </Col>
    </Row>
  );
};
