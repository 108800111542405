import { capFirstWordFirstLetter, capSentenceFirstLetter } from '@/util/CISUI';
import { css } from '@emotion/css';
import { InputNumber as AntInputNumber, Form } from 'antd';
import { useIntl } from 'react-intl';

export default function NumberInput({
  label = '',
  children,
  required = false,
  isTextFormat = true,
  ...props
}) {
  const intl = useIntl();
  return (
    <Form.Item
      name={props.name}
      rules={[
        {
          required: required,
          message: intl.formatMessage({
            id: `Please enter ${isTextFormat ? capSentenceFirstLetter(label) : label}`,
          }),
        },
      ]}
      label={intl.formatMessage({
        id: isTextFormat ? capSentenceFirstLetter(label) : label,
      })}
      initialValue={props.initialValue}
      className={css`
        .ant-form-item-label{
          padding-bottom: 0!important;
        }
      `}
    >
      <AntInputNumber
        type="number"
        className={`cis_inputnumber ${props.className}`}
        style={{ width: '100%' }}
        controls={props.controls || false}
        {...props}
        placeholder={intl.formatMessage({
          id: isTextFormat
            ? capFirstWordFirstLetter(props.placeholder || '')
            : props.placeholder || '',
        })}
      >
        {children}
      </AntInputNumber>
    </Form.Item>
  );
}
