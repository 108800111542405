import { css } from "@emotion/css";
import { Form, Switch } from "antd";
import React from "react";
import { useIntl } from "react-intl";

const InputSwitch = ({
  required = true,
  disabled = false,
  label = "",
  addOn = "",
  defaultValue = false,
  ...arg
}) => {
  const intl = useIntl();
  return (
    <>
      <Form.Item
        rules={[
          {
            required: required,
            message: "Plase Select Yes or No",
          },
        ]}
        extra={addOn === "" ? "" : intl.formatMessage({ id: addOn })}
        label={intl.formatMessage({ id: label })}
        {...arg}
        valuePropName="checked"
        className={css`
          .ant-form-item-label {
          padding-bottom: 0!important;
          }
        `}
      >
        <Switch
          checkedChildren="Yes"
          unCheckedChildren="No"
          disabled={disabled}
          defaultChecked={defaultValue}

        />
      </Form.Item>
    </>
  );
};

export default InputSwitch;
