import { PlusOutlined } from "@ant-design/icons";
import React from "react";
import { Link } from "react-router-dom";
import "./ListPrintBtn.less";

/**
 *
 * @param {string} param0
 * @returns
 */

const ListPrintBtn = ({ URL = "/" }) => {
  return (
    <>
      <div className="form__print__list">
        <Link to={URL}>
          <PlusOutlined title="Add" />
        </Link>
        {/* <FileExcelFilled />
                <FilePdfFilled />
                <PrinterFilled />
                <FileWordFilled /> */}
      </div>
    </>
  );
};

export default ListPrintBtn;
