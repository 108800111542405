import {
  CheckCircleFilled,
  CloseCircleOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import "./SubmitBtn.less";

/**
 *
 * @param {Boolean} isLoading - loading state
 *
 */
const SubmitBtn = ({ isLoading , cancelAllow = true}) => {
  const history = useHistory();
  return (
    <div className="submit__btn">
        {
          cancelAllow ? <>
              <Button icon={<CloseCircleOutlined />} onClick={history.goBack}>
                  Cancel
              </Button>
          </> : <></>
        }
      <Button icon={<ReloadOutlined />} htmlType="reset">
        Reset
      </Button>
      <Button
        type="primary"
        icon={<CheckCircleFilled />}
        htmlType="submit"
        loading={isLoading}
        disabled={isLoading}
      >
        Submit
      </Button>
    </div>
  );
};

export default SubmitBtn;
